<template>
	<div class="app-container">
		<el-form ref="postForm" :model="postForm" label-width="80px" label-position="top" class="mt-3" @submit.native.prevent>
			<div class="col-12">
				<div class="row">
					<div class="col-12 col-sm-6 offset-sm-3">
						<b-form-group label-cols="12" label-cols-lg="4">
							<template slot="label">{{$t('admin_general.table_user')}}</template>
							<b-form-input v-model="postForm.member" :placeholder="$t('admin_general.table_user')" class="w-100"></b-form-input>
						</b-form-group>
									
						<el-button type="primary" @click="onSubmit" :loading="buttonloading">{{$t('button.submit')}}</el-button>
						<el-button @click="onClose" type="danger" :loading="buttonloading">{{$t('button.close')}}</el-button>
					</div>
				</div>

				<div class="row mt-3">
					<div class="col-12 col-sm-6 offset-sm-3">
						<el-card shadow="never">
							<label>{{$t('admin_general.table_result')}}</label><br/>
							<div v-if="memberDetail.login != ''" class="table-responsive">
								<table class="table table-bordered" style="font-size:14px;">
									<tbody>
										<tr>
											<td width="30%"><b>{{$t('admin_general.table_user')}}</b></td>
											<td width="70%">{{memberDetail.login}}</td>
										</tr>
										<tr>
											<td width="30%"><b>{{$t('admin_general.table_name')}}</b></td>
											<td width="70%">{{memberDetail.name}}</td>
										</tr>
										<tr>
											<td width="30%"><b>{{$t('admin_general.table_ic')}}</b></td>
											<td width="70%">{{memberDetail.icpass}}</td>
										</tr>
										<tr>
											<td width="30%"><b>{{$t('admin_general.table_password')}}</b></td>
											<td width="70%">{{memberDetail.password}}</td>
										</tr>
										<tr>
											<td width="30%"><b>{{$t('admin_general.table_security')}}</b></td>
											<td width="70%">{{memberDetail.security}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { getLocalStorage } from "@/system/store/localstorage";
import { postMethod } from "@/system/model/post/post";
import { getDAES, getEAES } from "@/system/library/security";

export default{
	data(){
		return {
			loading: true,
			buttonloading: false,
			permissionList: [],
			errors: [],
			cronList: [],
			result: '',
			submitForm: {
				id: ''
			},postData: {
				data: '',
				language: ''
			},postForm: {
				member: '',
				security: ''
			},permission:{
                DBsubmit:'JUCRTF',
            },
			memberDetail: {
				login: '',
				icpass: '',
				name: '',
				password: '',
				security: ''
			}
		}
	}, methods: {
		popupErrorMessage(text){
			this.$alert(text, this.$t('msg.msg_error_title'), {
				type: 'error',
				dangerouslyUseHTMLString: true
			});
			
			this.errors = [];
		},resetPostData(){
			this.postData.data = '';
		},onSubmit(){
			if(this.permissionList.includes(this.permission.DBsubmit)){
				this.buttonloading = true;
				this.loading = true;
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.msg_run_task'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputType: 'password'
				}).then(({ value }) => {
					this.postForm.security = value;
					this.postData.data = getEAES(JSON.stringify(this.postForm));
					var self = this;
					var text = '';
					var result = postMethod("developer/password/DBsubmit", this.postData);
					result.then(function(value) {
						var data = JSON.parse(getDAES(value.data));

						if(value.valid){
							self.$message({
								type: 'success',
								message: data.returnMsg
							});
							
							self.memberDetail = data.memberDetail;
							self.postForm.security = '';
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.popupErrorMessage(text);
						}
						
						self.loading = false;
						self.buttonloading = false;
					});
				}).catch(() => {
					this.loading = false;
					this.buttonloading = false;
				});       
			}
		},onClose(){
			this.$emit('closeCurrentTags');
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.postData.language = currentLang;
	}
}
</script>